@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@angular/cdk/overlay-prebuilt.css';
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  color: #858585;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  margin: 0;
  box-shadow: none;
  background: none;
  border: none;
}

.emoji-mart-anchor:focus {
  outline: 0;
}

.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform; /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
  position: relative;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 5px 25px 6px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 3px;
  right: 11px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: none;
  background: none;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.95);
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: 0.2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px;
  right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: 0.5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s;
}

.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: 0.03s;
}

.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: 0.06s;
}

.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: 0.09s;
}

.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: 0.12s;
}

.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: 0.15s;
}

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a;
}

.emoji-mart-skin-tone-2 {
  background-color: #fadcbc;
}

.emoji-mart-skin-tone-3 {
  background-color: #e0bb95;
}

.emoji-mart-skin-tone-4 {
  background-color: #bf8f68;
}

.emoji-mart-skin-tone-5 {
  background-color: #9b643d;
}

.emoji-mart-skin-tone-6 {
  background-color: #594539;
}

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Dark mode styles
 */
.emoji-mart-dark {
  color: #fff;
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  border-color: #555453;
  background-color: #2f2f2f;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #222;
  color: #fff;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf;
}

html,
body {
  height: 100%;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100% !important;
  background-color: #EAF3FF !important;
}

@font-face {
  font-family: "Avenir";
  src: url("./assets/Fonts/Avenir.ttc");
}
@font-face {
  font-family: "Inter-Bold";
  src: url("./assets/Fonts/Inter/static/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter-ExtraBold";
  src: url("./assets/Fonts/Inter/static/Inter-ExtraBold.ttf");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: url("./assets/Fonts/Inter/static/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "Inter-Medium";
  src: url("./assets/Fonts/Inter/static/Inter-Medium.ttf");
}
@font-face {
  font-family: "Inter-Regular";
  src: url("./assets/Fonts/Inter/static/Inter-Regular.ttf");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("./assets/Fonts/poppins/Poppins-Bold.ttf");
}
button {
  border: 0px !important;
  box-shadow: none !important;
  outline: 0px !important;
}

button:focus {
  border: 0px !important;
  box-shadow: none !important;
  outline: 0px !important;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  width: 100%;
  border-radius: 49px !important;
  padding: 0px 15px 0px 15px !important;
  border: 0.5px solid #BFD1E3;
  background-color: white;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  outline: 0px !important;
  height: 64px;
  padding: 0px 10px 0px 15px;
  display: flex;
  align-items: center;
  border-top: 0px;
}

.mat-form-field-underline {
  width: 0px !important;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  display: none;
}

.mat-form-field {
  color: #1D1D1E;
}

.mat-select-arrow {
  color: #1D1D1E !important;
  background-image: url("./assets/images/arrow-down.svg");
  background-size: 20px;
  width: 20px !important;
  height: 20px !important;
  background-repeat: no-repeat;
  border: 0px !important;
}

button:focus {
  box-shadow: none !important;
  border: 0px !important;
}

.cp {
  cursor: pointer;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: black;
}

.filter {
  top: 95px;
  bottom: 15px;
  position: absolute !important;
}
@media (max-width: 768px) {
  .filter .mat-dialog-container {
    top: 20px;
    margin: 10px;
  }
}
@media (max-width: 768px) {
  .filter {
    right: auto !important;
    max-width: 100% !important;
  }
}
.filter .mat-tab-label {
  border-radius: 40px;
  min-width: 126px;
  opacity: 1;
  border: 0px;
  color: black;
  background-color: transparent;
  border: 0.5px solid #BFD1E3;
  font-family: "Inter-Medium" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #0A0045 !important;
}
.filter .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}
.filter .mat-tab-nav-bar,
.filter .mat-tab-header {
  border: 0px;
}

.interested .mat-form-field-appearance-fill .mat-form-field-flex {
  width: 160px;
}
.interested .mat-form-field-wrapper {
  padding: 0px !important;
}

.payNow button {
  width: 240px !important;
  max-width: 100%;
}

.breadcrumb-item {
  font-family: "Inter-SemiBold" !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  color: #323B4B !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  margin-top: 2px;
}

.mat-button-toggle-button {
  padding: 0px 5px !important;
  border: 1px solid #DBE9F5 !important;
  box-sizing: border-box;
  border-radius: 40px !important;
  font-family: "Inter-SemiBold";
  font-size: 14px;
  font-weight: 600;
}

.mat-button-toggle-group {
  border: 0px;
  display: flex;
  flex-wrap: wrap;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border: 0px;
}

.mat-button-toggle {
  border-radius: 40px;
  margin: 8px;
  font-family: "Inter-SemiBold" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #0A0045 !important;
}

.mat-button-toggle-checked {
  background-color: #EE7402;
  color: white !important;
  border: 0px !important;
}

.mat-button-toggle-appearance-standard {
  border-radius: 40px;
}

.user-detail .report {
  background-color: #FF4746;
  border: 0px !important;
  color: white !important;
}
.user-detail .cancel {
  border: 1px solid #DBE9F5 !important;
  background-color: transparent !important;
  color: #0A0045 !important;
}

.yespost h1 {
  font-size: 18px !important;
}
.yespost p {
  font-size: 14px !important;
}
.yespost .cancel {
  background-color: #EE7402 !important;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  position: absolute;
  bottom: 0px;
  left: 50%;
}

.addNew {
  top: 95px !important;
  bottom: 10px !important;
  position: absolute !important;
}

.detail {
  position: absolute !important;
  right: 230px;
  top: 60px;
}

.products h1 {
  font-size: 18px !important;
}
.products p {
  font-size: 14px !important;
}

.mat-dialog-container {
  border-radius: 30px !important;
}

.mat-select-panel {
  margin-top: 60px;
}

.mat-option.mat-active {
  background-color: white;
}

.mat-select-panel:not([class*=mat-elevation-z]) {
  border-radius: 8px;
}

.sales-filter {
  top: 175px;
  bottom: 10px;
  position: absolute !important;
  right: 20px;
  height: auto;
  max-height: auto;
}
.sales-filter .mat-form-field-wrapper {
  padding: 0px !important;
}
@media (max-width: 768px) {
  .sales-filter .mat-dialog-container {
    border-radius: 0px !important;
    padding: 0px !important;
    margin: 10px;
  }
}
@media (max-width: 768px) {
  .sales-filter {
    right: auto !important;
    max-width: 100% !important;
    bottom: 0px;
    top: auto;
  }
}

.notificationdata .form-check-input:checked {
  border-color: #EE7402;
  background-color: #EE7402;
}
.notificationdata .form-check-input:focus {
  box-shadow: none;
}

.mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.dispatch .cancel {
  background-color: #EE7402 !important;
}
.dispatch .modal-header {
  padding-top: 0px !important;
}
.dispatch .modal-body {
  padding-bottom: 0px !important;
}
.dispatch .modal-body img {
  display: none;
}
.dispatch h1 {
  padding-top: 0px;
}

.credit {
  position: absolute !important;
  top: 160px;
  right: 35px;
  bottom: 10px;
}
@media (max-width: 768px) {
  .credit .mat-dialog-container {
    margin: 10px;
  }
}
@media (max-width: 768px) {
  .credit {
    right: auto !important;
    max-width: 100% !important;
    bottom: 0px;
    top: auto;
  }
}

.resetPassword button {
  width: 100%;
}

.otp-input {
  border: 0px !important;
  border-bottom: 1px solid #23C99A !important;
  border-radius: 0px !important;
  outline: 0px !important;
}

.otp-input-error {
  border: 0px !important;
  border-bottom: 1px solid red !important;
  border-radius: 0px !important;
  outline: 0px !important;
}

.addNew {
  position: absolute !important;
  top: 10px;
}

.postPublish .report {
  width: 200px;
}

.mat-select-arrow-wrapper {
  display: inherit !important;
  vertical-align: inherit !important;
  height: 0px !important;
}

::-webkit-scrollbar {
  width: 0px !important;
  border-radius: 15px;
}

::-webkit-scrollbar-track {
  background: grey !important;
}

::-webkit-scrollbar-thumb:hover {
  background: grey !important;
}

.phonedetail .mat-select-placeholder {
  font-family: "Inter-Medium" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #5B6D8E !important;
}
@media (max-width: 768px) {
  .phonedetail .mat-select-placeholder {
    font-size: 14px !important;
  }
}
.phonedetail .mat-select-value {
  font-family: "Inter-Medium" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #0A0045 !important;
}
@media (max-width: 768px) {
  .phonedetail .mat-select-value {
    font-size: 14px !important;
  }
}

.phonedetails .mat-select-placeholder {
  font-family: "Inter-Medium" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #768DA9 !important;
}
@media (max-width: 768px) {
  .phonedetails .mat-select-placeholder {
    font-size: 14px !important;
  }
}
.phonedetails .mat-select-value {
  font-family: "Inter-Medium" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #0A0045 !important;
}

.datepicker .mat-input-element {
  font-family: "Inter-Medium" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #0A0045 !important;
}

.invalid-feedback {
  font-size: 12px !important;
}

.cursor {
  cursor: pointer;
}

.form-control.is-invalid {
  border-color: red;
}

.resetPassword .btn {
  margin-top: 20px;
  width: 100% !important;
}

.mat-button-toggle {
  margin: 0px !important;
  margin-right: 10px !important;
  margin-bottom: 20px !important;
}

.mat-select-placeholder {
  font-family: "Inter-Medium" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #768DA9 !important;
}
@media (max-width: 768px) {
  .mat-select-placeholder {
    font-size: 14px !important;
  }
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  display: inline !important;
}

option:disabled {
  color: -internal-light-dark(graytext, red) !important;
}

.form-select.is-invalid:not([multiple]):not([size]) {
  background-size: 16px 12px, calc(0.75em + 0.375rem) 0px !important;
}

.location[_ngcontent-fuh-c185]::after {
  position: absolute;
  right: 22px !important;
  bottom: 28px !important;
}

.owl-item img {
  border-radius: 10px;
}
.owl-item video {
  border-radius: 10px;
}

.productdetails .owl-prev {
  top: 37%;
  left: 3px;
  position: absolute;
}
.productdetails .owl-next {
  top: 37%;
  right: 3px;
  position: absolute;
}

.owl-prev {
  top: 40%;
  left: 0px;
  position: absolute;
}

.owl-next {
  top: 40%;
  right: 0px;
  position: absolute;
}

.owl-theme .owl-nav [class*=owl-] {
  background-color: transparent !important;
}

.card-carousal .owl-prev {
  top: 40%;
  left: 0px;
  position: absolute;
}
.card-carousal .owl-next {
  top: 40%;
  right: 0px;
  position: absolute;
}
.card-carousal img {
  border-radius: 0px;
}
.card-carousal video {
  border-radius: 0px;
}

.minimumFile {
  font-size: 12px;
  color: #dc3545;
}

.mat-icon-button .mat-button-wrapper {
  background-image: url("./assets/images/calendar-5.svg") !important;
  background-size: 25px 35px;
  width: 25px;
  height: 35px;
  background-repeat: no-repeat;
}
.mat-icon-button .mat-button-wrapper svg {
  opacity: 0;
}

input:focus {
  box-shadow: none;
  outline: none;
}

.mat-button-toggle-appearance-standard {
  box-shadow: none !important;
}

.ngx-pagination .pagination-next a::after {
  display: none !important;
}

.ngx-pagination .pagination-next.disabled::after {
  display: none !important;
}

.ngx-pagination .pagination-previous a::before {
  display: none !important;
}

.ngx-pagination .pagination-previous.disabled::before {
  display: none !important;
}

.ngx-pagination a {
  margin-left: 5px;
}

.ngx-pagination {
  margin-left: 0 !important;
  margin-bottom: 1rem !important;
  line-height: 20px !important;
  padding: 7px 15px 7px !important;
  font-family: "Inter-Medium" !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #5B6D8E !important;
}
.ngx-pagination a {
  text-decoration: none !important;
}

.owl-item img {
  object-fit: cover;
}

.home .owl-item img {
  object-fit: inherit;
}

.mat-option-text {
  font-family: "Inter-Medium" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #0A0045 !important;
}
@media (max-width: 768px) {
  .mat-option-text {
    font-size: 14px !important;
  }
}

.select-box-alert {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

small {
  color: #dc3545;
  font-size: 12px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.owl-theme .owl-nav .disabled {
  opacity: 1 !important;
}

.upload_files {
  border: 1px solid #BFD1E3;
  border-radius: 10px;
  height: 90px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #768DA9;
}

.mat-step-header:hover {
  background-color: white !important;
}

.mat-step-header.cdk-program-focused {
  background-color: white;
}

.mat-step-header.cdk-mouse-focused {
  background-color: white;
}

.mat-step-header.cdk-focused {
  background-color: white;
}

::ng-deep .toast-bottom-right {
  position: fixed;
  bottom: 50px;
}

@media screen and (max-width: 767px) {
  .otp-input {
    width: 50px !important;
    height: 50px !important;
  }
}
@media screen and (max-width: 407px) {
  .otp-input {
    width: 40px !important;
    height: 40px !important;
  }
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
  font-family: "Inter-Medium" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #0A0045 !important;
}
@media (max-width: 768px) {
  .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value {
    font-size: 14px !important;
  }
}

.ng-select .ng-clear-wrapper {
  display: none;
}

.ng-select span {
  font-family: "Inter-Medium";
  font-weight: 500;
}

@media (max-width: 768px) {
  .mat-horizontal-content-container {
    padding: 0px !important;
  }
}
.password-message {
  color: #768DA9 !important;
}

.uploadedImg {
  object-fit: cover;
}

.videoBackground {
  background: #F2F8FF;
}

::ng-deep .cdk-overlay-pane {
  transform: translateX(0) translateY(-15px) !important;
}

::ng-deep .cdk-overlay-container {
  position: absolute !important;
}